@import "../neat/neat-helpers";
$max-width: 1400px;
$wide-container-padding: 53px;
$global-padding: 30px;
$header-height: 130px;
$header-height-short: 90px;
$footer-height: 193px;
$footer-height-short: 140px;
$scalloped-width: 30px;

// NEAT EXAMPLES

// div.container {
//   @include outer-container;
// }

// div.element {
//   @include span-columns(6);
// }


// Define your breakpoints
$ptab: new-breakpoint(601px);
$tab: new-breakpoint(769px);
$desk: new-breakpoint(1025px);
$wideDesk: new-breakpoint(1201px);
$mobileOnly: new-breakpoint(max-width 600px);
$ptabAndLess: new-breakpoint(max-width 768px);
$smDeskAndLess: new-breakpoint(max-width 1024px);

$homeBP1: new-breakpoint(max-width 1281px);
$homeBP2: new-breakpoint(max-width 1152px);

$minHomepageHeight: new-breakpoint(max-height 982px);
$laptopHeightOnly: new-breakpoint(max-height 768px);

$breakpoint: new-breakpoint(max-width 768px);