@mixin animateFromBottom($bottompx) {
	opacity: 0;
	transform: translateY($bottompx);
	transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
	&.show {
		opacity: 1;
		transform: none;
	}
}

/**
 * Box sizing
 */
html {
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

html,
body {
  width:100%;
  height:100%;
  font-size: 17px;
}

body {
	padding-top: $header-height*2;
	background: #FFF;
	font-family: $font;
	line-height: 1.4;
	font-weight: 300;
	color: $dark-grey;
	@include media ($laptopHeightOnly) {
		padding-top: ($header-height-short*2) - 25px;
	}
	@include media ($smDeskAndLess) {
		padding-top: $header-height*1.3;
	}
	@include media ($ptabAndLess) {
		padding-top: $header-height + $wide-container-padding;
	}
	@include media ($mobileOnly) {
		padding-top: $header-height-short + $global-padding;
	}
	@media print {
		font-size: 10px !important;
		color: #000;
		padding-top: 0 !important;
		margin: 48pt;
		width: auto;
	}
}
	

a {
	color: $brand-blue;
	font-weight: bold;
	text-decoration: none;
	transition: all 0.2s ease;
	&:hover {
		cursor: pointer;
		color: $grey;
	}
	&.phone-link {
		color: inherit;
	}
}
p {
	margin: 0;
}
address {
	margin: 0;
	text-align: left;
	font-style: normal;
}


// Headings.
// -----------------------------------
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	@media print {
		color: #000;
		margin: 2rem 0 1rem;
	}
}

h1, h2 {
	color: $grey;
	@media print {
		color: #000;
	}
}

.page-title {
	margin: 0 0 37px 0;
	@include media($laptopHeightOnly) {
		margin: 30px 0;
	}
	h1 {
		margin-top: 0;
		font-weight: 600;
		@include font-size(40px);
		line-height: 0.93em;
		@include media($laptopHeightOnly) {
			@include font-size(36px);
		}
		@include media($ptabAndLess) {
			@include font-size(32px);
		}
		@media print {
			font-size: 1.8rem !important;
		}
		
	}
	.supersize {
		margin-top: 0;
		margin-bottom: 30px;
		font-weight: 600;
		color: $grey;
		@include font-size(85px);
		line-height: 100px;
		@include media($laptopHeightOnly) {
			@include font-size(73px);
		}
		+ h1 {
			margin-top: 30px;
			@include media($laptopHeightOnly) {
				@include font-size(41px);
			}
		}
	}
}

h2, .h2 {
	font-weight: 600;
	@include font-size(43px);
	line-height: 1.1em;
	@include media($laptopHeightOnly) {
		@include font-size(34px);
	}	
	@include media ($mobileOnly) {
		@include font-size(28px);
	}
	&.sm {
		@include font-size(22px);
		line-height: 1.27em;
		font-weight: 600;
		letter-spacing: 0;
	}
	@media print {
		font-size: 14px !important;
	}
}

h4 {
	@include font-size(24px);
	line-height: 28px;
	font-weight: 600;
	@media print {
		font-size: 1rem !important;
	}
}


// Global Styles
// -----------------------------------

.hidden{
  display: none;
}

#browser-warning {
	position:absolute;
	top:0;
	left:0;
	right:0;
	text-align:center;
	background:#ffc208;
	font-size:24px;
	z-index:1000;
	padding:40px;
	color:#000;

	strong {
		font-weight:bold;
	}
}

.container, .container-narrow {
	margin: 0 auto;
	max-width: $max-width;
	padding: 0 $global-padding;
}
.container {
	max-width: $max-width;
}
.container-narrow {
	max-width: 555px + ($global-padding*2);
	@media print {
		max-width: 100%;
	}
	.not-front & {
		position: relative;
		.page-icon {
			position: absolute;
			right: 100%;
			top: -10px; // Visual offset
			
			@media print {
				display: none;
			}
			@include media ($ptabAndLess) {
				position: static;
			}
			// @include media ($mobileOnly) {
			// 	margin-top: $global-padding;
			// }
			svg {
				width: 120px;
				height: 120px;
			}
			&.blue {
				color: $brand-blue;
			}
			&.teal {
				color: $brand-teal;
			}
			&.orange {
				color: $brand-orange;
			}
			&.grey {
				color: $grey;
			}
		}
	}
}

// admin link to help people edit pages when you disable the full admin bar
.admin-edit-link {
	background: #f1f1f1;
	box-shadow: inset 2px 2px 5px rgba(0,0,0,0.5);
	padding: 7px;
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 30;
	border-radius: 100px;
	&:before {
		content: " ";
		display: block;
		background: transparent;
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		bottom: 0;
		right: 0;
		border-radius: 100px;
		box-shadow: -2px -4px 5px -2px rgba(255,255,255,0.3);
	}
	.post-edit-link {
		display: block;
		border-radius: 100px;
		padding: 0.5em 1em;
		background: rgba(0, 82, 154, 0.75);
		color: #fff;
		&:hover {
			background: darken($brand-blue, 0.15);
			color: #fff;
			transform: rotateY(20deg);
			box-shadow: 9px 2px 5px -2px rgba(0,0,0,0.5);
		}
	}
}


.vertical-align {
	left: 50%;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
}

header, ul.page-subsections {
	transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

header, #floating-btn, .section-menu {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
}
header {
	top: 0;
	width: 100%;
	height: $header-height;
	padding: 23px $wide-container-padding;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: $brand-blue;
	html.ie9 & {
		//min-height: 120px;
		&:after {
			content:'';
			display: block;
			clear: both;
		}
	}
	@media print {
		display: block !important;
		min-height: 100px !important;
		background: #fff !important;
		opacity: 1 !important;
		padding: 0;
		position: static !important;
		transform: none !important;
		.header-nav,
		.navburger {
			display: none;
		}
		a.logo {
			color: #000 !important;
			display: block;
			min-height: 100px;
			span {
				display: block;
				color: #000 !important;
			}
		}
	}
	@include media ($breakpoint) {
		padding: 23px $global-padding;
	}
	.front & {
		background: rgba($brand-blue,0.7);
		@include media ($breakpoint) {
			background: $brand-blue;
		}
	}
	@include media ($laptopHeightOnly) {
		height: $header-height-short;
	}
	&, a {
		color: #fff;
	}
	&:hover {
		background: $brand-blue;
	}
	&.orange {
		background: $brand-orange;
		&:hover {
			background: $brand-orange;
		}
	}
	&.teal {
		background: $brand-teal;
		&:hover {
			background: $brand-teal;
		}
	}
	&.hide {
		opacity: 0;
		transform: translateY(-$header-height);
		transition-delay: 0.1s;
	}
	.logo {
		margin-right: auto; // Need this for FireFox
		display: flex;
		html.ie9 & {
			display: block;
			float: left;
			span {
				display: block;
			}
		}
		flex-flow: column nowrap;
		font-size: 30px;
		line-height: 0.83em;
		font-weight: 600;
		&:hover {
			color: inherit;
		}
		@include media($laptopHeightOnly) {
			font-size: 24px;
		}
		@include media($smDeskAndLess) {
			font-size: 24px;
		}
		@include media($mobileOnly) {
			font-size: 19px;
		}
	}
	.header-nav {
		margin: 0 auto; // Need this for FireFox
		ul {
			margin: 0 40px 0 auto;
			padding: 0;
			display: flex;
			justify-content: flex-end;
			transition: all 0.2s ease;
			html.ie9 & {
				display: block;
				float: left;
			}
			li {
				position: relative;
				list-style: none;
				display: block;
				margin: 0;
				padding: 32px 22px;
				transition: all 0.3s ease;
				border-radius: 20px 20px 0 0;
				html.ie9 & {
					display: inline-block;
					margin: 0 0 0 20px;
				}
				a {
					display: flex;
					font-weight: 600;
					font-size: 22px;
					line-height: 1em;
					color: #fff;
					position: relative;
					z-index: 0;
					@include media($laptopHeightOnly) {
						font-size: 20px;
					}
				}
				div {
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					align-content: center;
					span {
						margin-bottom: 8px;
						font-size: 12px;
						font-weight: normal;
						text-transform: uppercase;
						html.ie9 & {
							display: block;
						}
						@include media($laptopHeightOnly) {
							margin-bottom: 0;
						}
					}
				}
				svg {
					.fill-me {
						fill: #fff;
					}
				}
				.sub-menu {
					flex-flow: column nowrap;
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 0;
					min-width: 100%;
					height: auto;
					padding: 0;
					border-radius: 0 20px 20px 20px;
					li {
						padding: 0 22px;
						opacity: 0;
						a {
							font-size: 18px;
							white-space: nowrap;
							&:hover {
								opacity: 0.35;
							}
						}
						&:first-child {
							transform: translateY(-20%);
						}
						&:nth-child(2) {
							transform: translateY(-40%);
							transition-delay: 0.05s;
						}
						&:nth-child(3) {
							transform: translateY(-60%);
							transition-delay: 0.1s;
						}
					}
				}
				&.current-menu-item, &.current-menu-parent {
					a {
						color: lighten($grey, 7%);
					}
				}
				&:hover {
					a {
						z-index: 2;
					}
					&:not(.menu-item-has-children) {
						a {
							opacity: 0.7;
						}
					}
					.sub-menu {
						top: calc(100% - 40px);
						padding: 40px 0;
					}
					&.orange {
						&, .sub-menu {
							background: $brand-orange;
						}
					}
					&.teal {
						&, .sub-menu {
							background: $brand-teal;
						}
					}
					li {
						padding: 12px 22px;
						opacity: 1;
						&:first-child {
							transform: none;
						}
						&:nth-child(2) {
							transform: none;
						}
						&:nth-child(3) {
							transform: none;
						}
						&.current-menu-item {
							a {
								color: #fff;
								opacity: 0.4;
							}
						}
					}
				}
			}
			&.inner-nav {
				margin: 0 auto;
				justify-content: center;
				@include media ($smDeskAndLess) {
					padding: 0 $global-padding;
				}
				@include media ($breakpoint) {
					display: none;
				}
				li {
					margin-right: 40px;
					padding: 0;
					opacity: 0.5;
					&:last-child {
						margin-right: 0;
					}
					&.active {
						opacity: 0.9;
					}
					@include media($laptopHeightOnly) {
						margin-right: $global-padding/2;
					}
					@include media ($smDeskAndLess) {
						margin: 0;
						a {
							font-size: 20px;
						}
					}
					a {
						padding: 32px 22px;
					}
				}
				&:hover {
					li {
						opacity: 0.3;
						a {
							opacity: 1;
						}
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

#page-overlay {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0.8;
	display: none;
	@include media($breakpoint) {
		width: 100vw;
		height: 100vh;
	}
	@include media ($ptabAndLess) {
		z-index: -4;
	}
}

.navburger {
	margin-left: auto; // Need this for firefox
	position: relative;
	z-index: 11;
	width: 38px;
	text-align: center;
	@include media($breakpoint) {
		position: static;
	}
	.button {
		&:hover {
			cursor: pointer;
			.bar {
				background: $light-grey;
			}
		}
		span {
			transition: all 0.3s ease;
		}
		.label {
			font-size: 12px;
			text-transform: uppercase;
		}
		.bar {
			display: block;
			margin-top: 5px;
			width: 100%;
			height: 5px;
			border-radius: 5px;
			background: #fff;
			&.top {
				margin-top: 10px;
				@include media($laptopHeightOnly) {
					margin-top: 0;
				}
				@include media($smDeskAndLess) {
					margin-top: 0;
				}
			}
		}
	}
	&.open {
		.button {
			.label {
				opacity: 0;
			}
			.bar {
				background: $grey;
				&.top {
					transform: translateY(5px) rotate(45deg);
					-ms-transform:  translateY(5px) rotate(45deg);
				}
				&.middle {
					transform: translateX(-100%);
					-ms-transform: translateX(-100%);
					opacity: 0;
				}
				&.bottom {
					transform: translateY(-15px) rotate(-45deg);
					-ms-transform: translateY(-15px) rotate(-45deg);
				}
			}
		}
	}

	.navburger-menu {
		position: absolute;
		top: -15px;
		right: -15px;
		z-index: -1;
		padding: 140px 65px 50px 65px;
		background: $lightest-grey;
		border-radius: 28px;
		display: none;
		@include media($breakpoint) {
			padding-top: 100px;
			width: 100%;
			height: 100vh;
			top: 0;
			right: 0;
			border-radius: 0;
			overflow-y: auto;
		}
		@include media($mobileOnly) {
			padding: 100px $global-padding;
		}
		ul {
			margin: 0;
			padding: 0;
			text-align: right;
			@include media($breakpoint) {
				//text-align: left;
			}
			> li {
				padding: 0;
				margin: 0 0 $global-padding 0;
				list-style: none;
				@include media($breakpoint) {
					margin: 0 0 $global-padding/2 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&:not(.current-menu-item):hover, &:not(.current-menu-parent):hover {
					> a {
						color: $dark-grey;
					}
				}
				&.current-menu-item, &.current-menu-parent {
					> a {
						color: $dark-grey;
					}
				}
				a {
					display: block;
					font-size: 22px;
					font-weight: 600;
					color: $grey;
					white-space: nowrap;
					@include media($breakpoint) {
						font-size: 20px;
						line-height: 24px;
						//text-transform: uppercase;
						letter-spacing: 1px;//2px;
						white-space: normal;
					}
					@include media($mobileOnly) {
						letter-spacing: 1px;
					}
				}
				ul {
					display: none;
					@include media($breakpoint) {
						margin: $global-padding/2 0 $global-padding $global-padding;
						display: block;
						a {
							font-size: 16px;
							opacity: 0.8;
							text-transform: none;
							letter-spacing: 1px;
						}
					}
				}
			}
		}
		&.blue ul {
			> li:not(.current-menu-item):hover, > li:not(.current-menu-parent):hover {
				> a {
					color: $brand-blue;
				}
			}
			> li.current-menu-item, > li.current-menu-parent {
				> a {
					color: $brand-blue;
				}
			}
		}
		&.teal ul {
			> li:not(.current-menu-item):hover, > li:not(.current-menu-parent):hover {
				> a {
					color: $brand-teal;
				}
			}
			> li.current-menu-item, > li.current-menu-parent {
				> a {
					color: $brand-teal;
				}
			}
		}
		&.orange ul {
			> li:not(.current-menu-item):hover, > li:not(.current-menu-parent):hover {
				> a {
					color: $brand-orange;
				}
			}
			> li.current-menu-item, > li.current-menu-parent {
				> a {
					color: $brand-orange;
				}
			}
		}
	}

}

.bubble-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	background: $lightest-grey;
	color: $grey;
	transition: all 0.2s ease;
	svg {
		width: 20px;
		height: 20px;
		transition: all 0.2s ease;
	}
	@include media($breakpoint) {
		width: 60px;
		height: 60px;
		box-shadow: 1px 1px 10px rgba(0,0,0,0.15);
		svg {
			width: 30px;
			height: 30px;
		}
	}
	&:hover {
		cursor: pointer;
		color: $light-grey;
	}
}

.back-to-top {
	margin-left: auto;
	margin-bottom: 10px;
	transform: translateY(20%);
	opacity: 0;
	&.show {
		transform: none;
		opacity: 1;
	}
	@include media($breakpoint) {
		margin-bottom: $header-height-short - $global-padding/1.5;
	}
	html.ie9 & {
		svg {
			margin: 10px;
		}
	}
}

.expand-indicator {
	display: none;
	@include media($breakpoint) {
		display: flex;
		position: fixed;
		right: $global-padding/2;
		bottom: $global-padding/2;
		&:hover {
			cursor: pointer;
			color: $light-grey;
		}
		&.open {
			svg {
				transform: rotate(225deg);
			}
			+ .page-subsections {
				.back-to-top {
					margin-bottom: 10px;
				}
				ul {
					padding-bottom: $global-padding*2.2;
				}
			}
		}
		&:not(.hide) {
			z-index: 31;
		}
		&.hide {
			z-index: 9;
		}
	}
}
.page-subsections {
	position: fixed;
	
	@media print {
		display: none;
	}
	right: 18px;
	top: $header-height*2;
	z-index: 9;
	@include media($ptabAndLess) {
		top: $header-height + $global-padding;
	}
	@include media($breakpoint) {
		top: auto;
		right: $global-padding/2;
		bottom: $global-padding/2;
		z-index: 11;
	}
	@include media($wideDesk) {
	min-width: 300px;
	}
	ul {
		margin: 0;
		padding: 0;
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-end;
		transition: padding 0.2s ease;
		li {
			width: 100%;
			list-style: none;
			margin-bottom: 5px;
			@include media($breakpoint) {
				opacity: 0;
				margin: 0;
				max-height: 0;
				transform: translateY(20%);
				transition: all 0.2s ease;
				&.show {
					margin-bottom: 0;
					margin-top: 2px;
					opacity: 1;
					max-height: 42px;
					transform: none;
				}
				&.responsive-hide {
					display: none;
				}
			}
			a {
				display: block;
				outline: 0;
				padding: 10px 21px;;
				text-align: right;
				background: $lightest-grey;
				border-radius: 55px;
				color: $grey;
			}
			svg {
				width: 50px;
				height: 50px;
				flex-grow: 1;
			}
			svg, .fill-me {
				transition: fill 0.4s linear;
			}
			span {
				max-width: 0;
				white-space: nowrap;
				text-align: right;
				overflow: hidden;
				transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
			}
			&.active {
				a:hover {
					cursor: default;
				}
			}
			&:not(.active):hover {
				svg, .fill-me {
					fill: #fff;
				}
				a {
					color: #fff;
				}
				span {
					margin: 0 15px 0 45px;
					max-width: 300px;
				}
			}
		}
	}
	&.blue {
		ul {
			svg, .fill-me {
				fill: $brand-blue;
			}
			li:not(.active):hover {
				a {
					background: $brand-blue;
				}
			}
			li.active a {
				color: $brand-blue;
			}
		}
	}
	&.teal {
		ul {
			svg, .fill-me {
				fill: $brand-teal;
			}
			li:not(.active):hover {
				a {
					background: $brand-teal;
				}
			}
			li.active a {
				color: $brand-teal;
			}
		}
	}
	&.orange {
		ul {
			svg, .fill-me {
				fill: $brand-orange;
			}
			li:not(.active):hover {
				a {
					background: $brand-orange;
				}
			}
			li.active a {
				color: $brand-orange;
			}
		}
	}
	&.grey {
		ul {
			svg, .fill-me {
				fill: $grey;
			}
			li:not(.active):hover {
				a {
					background: $grey;
				}
			}
			li.active a {
				color: $grey;
			}
		}
	}
}

// ul.page-subsections {
// 	margin: 0;
// 	padding: $global-padding 0;
// 	position: fixed;
// 	left: 0;
// 	top: $header-height;
// 	z-index: 9;
// 	width: 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	background: #fff;
// 	@include media ($laptopHeightOnly) {
// 		top: $header-height-short;
// 		padding: $global-padding/2 0;
// 	}
// 	&.up {
// 		top: 0;
// 	}
// 	li {
// 		display: block;
// 		margin: 0 10px;
// 		list-style: none;
// 		a {
// 			display: block;
// 			padding: 14px 23px;
// 			font-size: 17px;
// 			color: $dark-grey;
// 			background: $lightest-grey;
// 			border-radius: 45px;
// 		}
// 		&.active, &:hover {
// 			a {
// 				color: #fff;
// 				background: $light-grey;
// 			}
// 		}
// 		&.printable {
// 			position: absolute;
// 			top: $global-padding;
// 			right: $global-padding;
// 		}
// 	}
// }

// #floating-btn {
// 	top: $header-height + $global-padding;
// 	left: $global-padding + 15;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	border-radius: 55px;
// 	&.up {
// 		top: $global-padding;
// 	}
// 	@include media ($laptopHeightOnly) {
// 		top: $header-height-short + $global-padding/2;
// 		&.up {
// 			top: $global-padding/2;
// 		}
// 	}
// 	.label, svg {
// 		transition: all 0.2s cubic-bezier(0.39, 0.58, 0.57, 1)
// 	}
// 	.label {
// 		transform: translateX(-100%);
// 		opacity: 0;
// 		color: $grey;
// 	}
// 	svg {
// 		width: 50px;
// 		height: 50px;
// 		padding: 8px;
// 		// border-radius: 50%;
// 		// background: $lightest-grey;
// 	}
// 	.fill-me {
// 		fill: $light-grey;
// 	}
// 	&:hover {
// 		background: $lightest-grey;
// 		.label {
// 			transform: none;
// 			opacity: 1;
// 			margin: 0 25px 0 10px;
// 			color: $dark-grey;
// 		}
// 		svg {
// 			transform: rotate(90deg);
// 		}
// 		.fill-me {
// 			fill: $grey;
// 		}
// 	}
// }

.page-subsection-anchor {
	@include media($breakpoint) {
		transform: translateY(100px);
		display: block;
	}
}

.page-end-nav {
	margin-top: 130px;
	display: flex;
	
	html.ie9 & {
		display: block;
		&:after {
			content:'';
			display: block;
			clear: both;
		}
	}
	@media print {
		display: none;
	}
	
	justify-content: center;
	align-items: center;
	@include media ($ptabAndLess) {
		margin-top: 80px;
	}
	a, span {
		display: block;
		html.ie9 & {
			float: left;
		}
		width: 50%;
		padding: 41px 35px;
		color: #fff;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.2em;
		@include media ($smDeskAndLess) {
			font-size: 22px;
		}
		@include media ($ptabAndLess) {
			padding: $global-padding;
			font-size: 22px;
		}
		@include media ($mobileOnly) {
			padding: $global-padding/2;
			font-size: 17px;
		}
		svg {
			margin: 0 10px;
			width: 34px;
			height: 20px;
			transition: transform 0.2s ease;
			@include media ($mobileOnly) {
				width: 20px;
				height: 11px;
			}
		}
		&:first-child {
			text-align: right;
			svg {
				transform: rotate(-90deg);
				//-ms-transform: rotate(-90deg);
			}
		}
		&:last-child {
			svg {
				transform: rotate(90deg);
				//-ms-transform: rotate(90deg);
			}
		}
	}
	a:hover {
		background: rgba(255,255,255,0.1);
		&:first-child {
			svg {
				transform: translateX(-10px) rotate(-90deg);
				//-ms-transform: translateX(-10px) rotate(-90deg);
			}
		}
		&:last-child {
			svg {
				transform: translateX(10px) rotate(90deg);
				//-ms-transform: translateX(10px) rotate(90deg);
			}
		}
	}
	span {
		opacity: 0.3;
	}
}

footer {
	width: 100%;
	display: flex;
	html.ie9 & {
		display: block;
		&:after {
			content:'';
			display: block;
			clear: both;
		}
		div,
		address {
			float: left;
		}
	}
	
	@media print {
		display: none;
	}
	justify-content: space-between;
	align-items: flex-start;
	padding: $wide-container-padding $wide-container-padding $wide-container-padding ($wide-container-padding - 15px); // Visually offsets the logo
	height: $footer-height;
	background: $brand-blue;
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	@include media($laptopHeightOnly) {
		padding: $global-padding $wide-container-padding;
		height: $footer-height-short;
	}
	@include media ($smDeskAndLess) {
		flex-wrap: wrap;
		height: auto;
		padding: $global-padding;
	}
	@include media ($mobileOnly) {
		padding-right: 90px;
	}
	a {
		color: #fff;
	}
	> *:not(.links) {
		margin-right: 55px;
		html.ie9 & {
			margin-right: 25px;
		}
		max-width: 197px;
		@include media ($smDeskAndLess) {
			margin-right: 15px;
			margin-bottom: 30px;	
		}
		@include media ($mobileOnly) {
			width: 45%;
			margin-right: 0;
		}
	}
	img {
		@include media ($mobileOnly) {
			max-height: 75px;
			width: auto;
		}
	}
	.tagline {
		width: 125px;
		line-height: 16px;
		font-weight: bold;
		text-transform: uppercase;
	}
	.links {
		margin-left: auto;
		margin-right: 0;
	}
	ul {
		margin: 0;
		padding: 0;
		display: inline-block;
		vertical-align: top;
		li {
			list-style: none;
			a {
				display: block;
			}
		}
		&.footer-links {
			margin-right: 50px;
			margin-bottom: $global-padding; 
			@include media ($smDeskAndLess) {
				margin-bottom: $global-padding; 
			}
			@include media ($ptabAndLess) {
				display: none;
			}
			li {
				a {
					font-weight: bold;
					font-size: 15px;
					line-height: 25px;
				}
			}
		}
		&.social-links {
			@include media($mobileOnly) {
				margin-left: 0;
			}
			li {
				display: inline-block;
				margin-left: 21px;
				&:first-child {
					margin-left: 0;
				}
				a {
					font-size: 31px;
					&:hover {
						color: $grey;
					}
				}
			}
		}
	}
}



// -----------------------------------
// BRAND STYLES
// -----------------------------------

.bg-blue {
	background: $brand-blue;
}
.bg-teal {
	background: $brand-teal;
}
.bg-orange {
	background: $brand-orange;
}
.bg-grey {
	background: $grey;
}


// -----------------------------------
// HOMEPAGE STYLES
// -----------------------------------

body.front {
	// padding-top: $header-height;
	padding-top: 0;
	width: 100%;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: stretch;
	background: $brand-blue url('../img/heroimg_homepage.jpg') no-repeat center top;
	background-size: cover;
	html.ie9 & {
		display: block;
	}
	@include media ($laptopHeightOnly) {
		padding-top: $header-height-short;
	}
	@include media ($mobileOnly) {
		padding-top: $header-height-short;
		background-position: center top $header-height-short/2;
		background-size: auto calc(100vh - #{$header-height-short*2.5});
	}
	main, .main {
		padding: 40px 30px;
	}
	.intro-layer {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 0 $wide-container-padding;
		z-index: 9;
		color: #fff;
		font-weight: 600;
		overflow: hidden;
		transition: all 0.4s ease;
		@include media ($breakpoint) {
			height: auto;
			padding: 0;
			// background: $brand-blue url('../img/heroimg_homepage.jpg') no-repeat center top;
			// background-size: 100% ($header-height + 100px);
			overflow: visible;
		}
		&.hide {
			opacity: 0;
		}
		.video-layer {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@include media ($breakpoint) {
				position: relative;
				height: calc(100vh - #{$header-height-short});
				align-items: flex-end;
			}
			.text {
				margin: 0 auto;
				min-width: 70%;
				max-width: $max-width;
				padding: 0 $global-padding;
				@include media ($minHomepageHeight) {
					margin-top: -100px;
				}
				@include media ($breakpoint) {
					padding: $global-padding;
					width: 100%;
					// margin-top: 300px;
					background: $brand-blue;
				}
				html.ie9 & {
					margin: $header-height 0;
				}
			}
			.play-btn, .close-btn {
				display: block;
				svg {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					border: 3px solid #fff;
					transition: background 0.2s linear;
				}
				&:hover {
					cursor: pointer;
					svg {
						background: rgba(255,255,255,0.3);
					}
				}
			}
			.play-btn {
				margin-top: $global-padding*2;
				max-width: 400px;
				display: flex;
				align-items: center;
				font-size: 24px;
				@include media ($breakpoint) {
					margin-top: $global-padding;
					font-size: 18px;
				}
				svg {
					margin-right: 15px;
					padding-left: 2px;
					padding-bottom: 2px;
					width: 50px;
					height: 50px;
					@include media ($breakpoint) {
						width: 40px;
						height: 40px;
					}
				}
			}
			.close-btn, iframe {
				opacity: 0;
				transition: opacity 0.5s ease;
				&.show {
					opacity: 1;
				}
			}
			.close-btn {
				position: absolute;
				top: $header-height + $global-padding;
				right: $global-padding;
				width: 40px;
				height: 40px;
				align-self: flex-start;
				z-index: 2;
				font-size: 25px;
				transform: scale(1,0);
				@include media ($laptopHeightOnly) {
					top: $global-padding;
				}
				@include media ($ptabAndLess) {
					top: $global-padding;
				}
				&.show {
					transform: none;
				}
				svg {
					padding: 2px;
				}
			}
			iframe {
				display: none;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				min-width: 100%;
				width: 100%;
				height: 100%;
			}
		}
		h1 {
			width: auto;
			margin-top: 0;
			margin-bottom: 30px;
			font-weight: 600;
			font-size: 110px;
			line-height: 0.82em;
			@include media($minHomepageHeight) {
				font-size: 83px;
			}
			@include media($laptopHeightOnly) {
				font-size: 69px;
			}
			@include media ($breakpoint) {
				font-size: 50px;
				br {
					display: none;
				}
			}
			@include media ($mobileOnly) {
				margin-bottom: 10px;
				font-size: 36px;
			}
		}
		.tagline {
			max-width: 638px;
			font-size: 50px;
			line-height: 1.1em;
			@include media ($minHomepageHeight) {
				font-size: 40px;
			}
			@include media ($breakpoint) {
				max-width: none;
				font-size: 22px;
			}
		}
		h1, .tagline {
			color: #fff;
			&.hide {
				opacity: 0;
				z-index: -1;
			}
		}
		&.hide {
			opacity: 0;
			z-index: -1;
		}
		.bg-texture {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 90%;
			height: 100%;
			background: rgba($brand-blue,0.2);
			&:after {
				position: absolute;
				top: 0;
				left: 100%;
				content: " ";
				width: 50px;
				height: 100%;
				background: url('../img/scalloped-edge-blue.svg') repeat-y left center;
				opacity: 0.2;
			}
		}
	}
	
	.close-btn, iframe {
		opacity: 0;
		transition: opacity 0.5s ease;
		&.show {
			opacity: 1;
		}
	}
	.close-btn {
		//position: absolute;
		position: fixed;
		top: $header-height + $global-padding;
		@include media($mobileOnly) {
			top: 80px;
		}
		right: $global-padding;
		width: 40px;
		height: 40px;
		align-self: flex-start;
		z-index: 102;
		font-size: 25px;
		transform: scale(1,0);
		cursor: pointer;
		//top: 40px;
		
		display: block;
		svg {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 3px solid;
			transition: background 0.2s linear;
		}
		&:hover {
			cursor: pointer;
			svg {
				background: rgba(255,255,255,0.3);
			}
		}
				
		@include media ($laptopHeightOnly) {
			//top: $global-padding;
		}
		@include media ($ptabAndLess) {
			//top: $global-padding;
		}
		&.show {
			transform: none;
		}
		svg {
			padding: 2px;
		}
	}
	
	iframe {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		min-width: 100%;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.5s ease;
		z-index: 100;
		background: rgba(255,255,255,.85);
		overflow: visible;
		&.show {
			opacity: 1;
		}
	}
	
	.audience-sides {
		position: absolute;
		left: 50%;
		top: calc(100% - 120px);
		transform: translateX(-50%);
		z-index: 9;
		width: 100%;
		max-width: $max-width;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		@include media($homeBP2) {
			top: calc(100% - 91px);
		}
		@include media ($breakpoint) {
			flex-wrap: wrap;
			position: static;
			transform: none;
		}
		.audience-side {
			width: 42%;
			margin: 0 2px;
			padding-bottom: 45px;
			border-radius: 30px 30px 0 0;
			transition: all 0.3s ease;
			@include media ($breakpoint) {
				width: 100%;
				margin: 0;
				border-radius: 0;
			}
			.audience-name, .audience-desc, .portals {
				margin-left: 35px;
				margin-right: 35px;
				@include media ($minHomepageHeight) {
					margin-left: $global-padding;
					margin-right: $global-padding;
				}
			}
			.audience-name {
				padding-top: 45px;
				padding-bottom: 31px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 24px;
				line-height: 1.4em;
				font-weight: 600;
				@include media ($minHomepageHeight) {
					font-size: 25px;
				}
				@include media($homeBP1) {
					font-size: 25px;
				}
				@include media($homeBP2) {
					padding-top: 31px;
					font-size: 21px;
				}
				@include media($mobileOnly) {
					font-size: 24px;
					line-height: 1em;
				}
				svg {
					margin-right: 20px;
					width: 37px;
					height: 37px;
					padding: 0 4px 4px 4px;
					border: 3px solid #fff;
					border-radius: 50%;
					transform-origin: center center;
					transition: all 0.2s ease;
					will-change: transform, background;
					@include media ($breakpoint) {
						display: none;
					}
				}
				&:hover {
					cursor: pointer;
					svg {
						background: rgba(255,255,255,0.2);
					}
				}
			}
			img {
				margin-right: 15px;
			}
			.audience-desc {
				font-size: 17px;
				line-height: 1.36em;
				//@include media($homeBP2) {
					//font-size: 17px;
				//}
				@include media ($breakpoint) {
					font-size: 16px;
				}
			}
			.audience-desc, .portals {
				opacity: 0;
				transition: opacity 0.3s ease;
				transition-delay: 0.2s;
				@include media ($breakpoint) {
					opacity: 1;
				}
			}
			&.active {
				transform: translateY(calc(-100% + 120px));
				transform: translateY(-100%) translateY(120px); // This weird one is for IE
				@include media ($breakpoint) {
					transform: none;
				}
				.audience-desc, .portals {
					opacity: 1;
				}
				.audience-name {
					svg {
						transform: rotate(180deg);
					}
				}
			}
			@include media($desk) {
				&:not(.active):hover {
					transform: translateY(-20px);
				}
			}
		}

		.portals {
			margin-top: 40px;
			display: flex;
			flex-flow: column nowrap;
			.portal {
				margin-bottom: 13px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 10px 20px 10px 35px;
				width: 100%;
				height: 65px;
				border: 1px solid #fff;
				border-radius: 30px;
				color: #fff;
				@include media($mobileOnly) {
					padding: 10px 20px;
				}
				.portal-cat {
					margin-top: 6px;
					margin-right: 17px;
					text-transform: uppercase;
					font-size: 12px;
					min-width: 3.5em;
				}
				.portal-name {
					font-size: 24px;
					line-height: 1em;
					@include media($homeBP2) {
						font-size: 20px;
					}
					@include media($mobileOnly) {
						font-size: 18px;
					}
				}
				svg {
					margin-left: auto;
					height: 100%;
					width: auto;
					max-width: 50px;
					.fill-me {
						fill: #fff;
					}
				}
				&:hover {
					background: #fff;
					color: $grey;
					svg {
						.fill-me {
							fill: $grey;
						}
					}
				}
			}
		}
	}

	// .side-intro {
	// 	position: absolute;
	// 	bottom: 0;
	// 	z-index: 9;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: flex-end;
	// 	width: 100%;
	// 	height: 120px;
	// 	background-repeat: no-repeat;
	// 	background-position: center;
	// 	background-size: cover;
	// 	transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
	// 	&.hide {
	// 		transform: translateY(105%);
	// 	}
	// 	.cta-btn {
	// 		outline: 0;
	// 		display: flex;
	// 		justify-content: flex-start;
	// 		align-items: flex-start;
	// 		width: 45%;
	// 		margin: 0 2px;
	// 		padding: 45px $wide-container-padding;
	// 		color: #fff;
	// 		font-weight: 600;
	// 		font-size: 31px;
	// 		line-height: 30px;
	// 		border-radius: 30px 30px 0 0;
	// 		@include media($minHomepageHeight) {
	// 			padding: 25px $global-padding;
	// 			font-size: 25px;
	// 		}
	// 		@media (max-width: 1318px) {
	// 			font-size: 25px;
	// 		}
	// 		img {
	// 			margin-right: 18px;
	// 		}
	// 		&:hover {
	// 			padding-bottom: 60px;
	// 			@include media($minHomepageHeight) {
	// 				padding-bottom: $global-padding + 5px;
	// 			}
	// 		}
	// 	}
	// }
	// .side {
	// 	width: 100%;
	// 	height: 100%;
	// 	transition: all 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
	// 	background-repeat: no-repeat;
	// 	background-position: center;
	// 	background-size: cover;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	opacity: 0;
	// 	.main {
	// 		opacity: 0;
	// 		width: 100%;
	// 		transition: opacity 0.2s ease;
	// 		.intro-overlay {
	// 			position: absolute;
	// 			z-index: 1;
	// 			left: 0;
	// 			top: 0;
	// 			width: 100%;
	// 			height: 100%;
	// 			overflow: hidden;
	// 			.container {
	// 				height: 100%;
	// 			}
	// 			.contents {
	// 				// margin-top: -$footer-height;
	// 				height: 100%;
	// 				padding: 26px;
	// 				position: relative;
	// 				display: flex;
	// 				justify-content: flex-start;
	// 				align-content: center;
	// 				align-items: center;
	// 				flex-flow: row wrap;
	// 				opacity: 0;
	// 				transition: all 0.5s ease;
	// 				// @include media($laptopHeightOnly) {
	// 				// 	margin-top: -$footer-height-short;
	// 				// }
	// 				&:before, &:after {
	// 					content: " ";
	// 					display: block;
	// 					position: absolute;
	// 					height: 100%;
	// 					top: 0;
	// 				}
	// 				&:before {
	// 					right: 100%;
	// 				}
	// 				&:after {
	// 					left: 100%;
	// 				}
	// 			}
	// 			h2, .tagline {
	// 				transition: opacity 0.5s ease;
	// 			}
	// 			h2 {
	// 				width: 100%;
	// 				margin-bottom: 17px;
	// 				font-size: 55px;
	// 				font-weight: normal;
	// 				line-height: 48px;
	// 				color: #fff;
	// 				@include media($minHomepageHeight) {
	// 					font-size: 50px;
	// 				}
	// 			}
	// 			.tagline {
	// 				font-size: 24px;
	// 				line-height: 29px;
	// 				font-weight: 600;
	// 			}
	// 		}
	// 	}
	// 	&.filter {
	// 		filter: blur(5px);
	// 	}
	// 	&.active {
	// 		filter: none;
	// 		opacity: 1;
	// 		overflow: auto;
	// 		width: 100%;
	// 		position: relative;
	// 		.main {
	// 			opacity: 1;
	// 		}
	// 		.contents {
	// 			width: calc(33% + 5px - #{$scalloped-width}); // Add gaps into width, minus the scalloped edge
	// 			color: #fff;
	// 			// @include media($minHomepageHeight) {
	// 			// 	width: 40%;
	// 			// }
	// 			&:before {
	// 				right: 100%;
	// 			}
	// 			&:after {
	// 				left: 100%;
	// 			}
	// 			&.in {
	// 				opacity: 1;
	// 			}
	// 		}
	// 		.portals {
	// 			// position: fixed;
	// 			position: absolute;
	// 			z-index: 2;
	// 			// bottom: $footer-height;
	// 			bottom: 0;
	// 			left: 0;
	// 			width: 100%;
	// 			overflow: hidden;
	// 			// @include media($laptopHeightOnly) {
	// 			// 	bottom: $footer-height-short;
	// 			// }
	// 			.container {
	// 				display: flex;
	// 				justify-content: center;
	// 				align-items: stretch;
	// 				flex-flow: row wrap;
	// 				.portal {
	// 					display: block;
	// 					width: 33%;
	// 					margin: 0 1px;
	// 					padding: $global-padding/3 $global-padding;
	// 					background: #fff;
	// 					color: $dark-grey;
	// 					border-radius: $global-padding $global-padding 0 0;
	// 					position: relative;
	// 					@include animateFromBottom(30px);
	// 					&.show {
	// 						opacity: 1;
	// 						z-index: 9;
	// 					}
	// 					.container {
	// 						display: flex;
	// 						justify-content: flex-end;
	// 					}
	// 					svg {
	// 						position: absolute;
	// 						top: $global-padding - 10;
	// 						right: $global-padding - 10;
	// 						z-index: -1;
	// 						width: 60px;
	// 						height: 60px;
	// 						@include media($minHomepageHeight) {
	// 							// top: $global-padding - 5px;
	// 							width: 50px;
	// 							height: 50px;
	// 						}
	// 					}
	// 					h3 {
	// 						width: calc(100% - 60px);
	// 						font-size: 24px;
	// 						font-weight: 600;
	// 						color: $grey;
	// 						@include media($minHomepageHeight) {
	// 							font-size: 20px;
	// 						}
	// 					}
	// 					p {
	// 						font-weight: normal;
	// 						font-size: 16px;
	// 						line-height: 22px;
	// 					}
	// 					&:hover {
	// 						@include media($laptopHeightOnly) {
	// 							transform: none;
	// 						}
	// 						h3 {
	// 							color: #fff;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.side-1 {
	// 		.contents {
	// 			transform: translateX(-50%);
	// 			&:before {
	// 				width: 300%;
	// 				background-image: none !important;
	// 			}
	// 			&:after {
	// 				width: 50px;
	// 				opacity: 0.75;
	// 				background-repeat: repeat-y;
	// 				background-position: left center;
	// 				background-color: transparent !important;
	// 			}
	// 			&.in {
	// 				transform: none;
	// 			}
	// 		}
	// 	}
	// 	&.side-2 {
	// 		.container {
	// 			display: flex;
	// 			justify-content: flex-end;
	// 		}
	// 		.contents {
	// 			transform: translateX(50%);
	// 			&:before {
	// 				width: 50px;
	// 				opacity: 0.75;
	// 				background-repeat: repeat-y;
	// 				background-position: right center;
	// 				background-color: transparent !important;
	// 			}
	// 			&:after {
	// 				width: 300%;
	// 				background-image: none !important;
	// 			}
	// 			&.in {
	// 				transform: none;
	// 			}
	// 		}
	// 	}
	// 	&.orange {
	// 		.contents {
	// 			&, &:before, &:after {
	// 				background-color: rgba($brand-orange, 0.75);
	// 			}
	// 			// &:before, &:after {
	// 			// 	background-image: url('../img/scalloped-edge-orange.svg');
	// 			// }
	// 		}
	// 		.portals {
	// 			.portal {
	// 				svg, .fill-me {
	// 					fill: $brand-orange;
	// 				}
	// 				&:hover {
	// 					background: $brand-orange;
	// 					svg, .fill-me {
	// 						fill : #fff;
	// 					}
	// 				}
	// 			}
	// 		}
	// 		&.side-1 {
	// 			.contents {
	// 				&:after {
	// 					background-image: url('../img/scalloped-edge-orange.svg');
	// 				}
	// 			}
	// 		}
	// 		&.side-2 {
	// 			.contents {
	// 				&:before {
	// 					background-image: url('../img/scalloped-edge-orange-flipped.svg');
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.teal {
	// 		.contents {
	// 			&, &:before, &:after {
	// 				background-color: rgba($brand-teal, 0.75);
	// 			}
	// 			// &:before {
	// 			// 	background-image: url('../img/scalloped-edge-teal-flipped.svg');
	// 			// }
	// 		}
	// 		.portals {
	// 			.portal {
	// 				svg, .fill-me {
	// 					fill: $brand-teal;
	// 				}
	// 				&:hover {
	// 					background: $brand-teal;
	// 					svg, .fill-me {
	// 						fill : #fff;
	// 					}
	// 				}
	// 			}
	// 		}
	// 		&.side-1 {
	// 			.contents {
	// 				&:after {
	// 					background-image: url('../img/scalloped-edge-teal.svg');
	// 				}
	// 			}
	// 		}
	// 		&.side-2 {
	// 			.contents {
	// 				&:before {
	// 					background-image: url('../img/scalloped-edge-teal-flipped.svg');
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.blue {
	// 		.contents {
	// 			&, &:before, &:after {
	// 				background-color: rgba($brand-blue, 0.75);
	// 			}
	// 		}
	// 		.portals {
	// 			.portal {
	// 				svg, .fill-me {
	// 					fill: $brand-blue;
	// 				}
	// 				&:hover {
	// 					background: $brand-blue;
	// 					svg, .fill-me {
	// 						fill : #fff;
	// 					}
	// 				}
	// 			}
	// 		}
	// 		&.side-1 {
	// 			.contents {
	// 				&:after {
	// 					background-image: url('../img/scalloped-blue-teal.svg');
	// 				}
	// 			}
	// 		}
	// 		&.side-2 {
	// 			.contents {
	// 				&:before {
	// 					background-image: url('../img/scalloped-blue-teal-flipped.svg');
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.grey {
	// 		.contents {
	// 			&, &:before, &:after {
	// 				background-color: rgba($grey, 0.75);
	// 			}
	// 		}
	// 		.portals {
	// 			.portal {
	// 				svg, .fill-me {
	// 					fill: $grey;
	// 				}
	// 				&:hover {
	// 					background: $grey;
	// 					svg, .fill-me {
	// 						fill : #fff;
	// 					}
	// 				}
	// 			}
	// 		}
	// 		&.side-1 {
	// 			.contents {
	// 				&:after {
	// 					background-image: url('../img/scalloped-grey-teal.svg');
	// 				}
	// 			}
	// 		}
	// 		&.side-2 {
	// 			.contents {
	// 				&:before {
	// 					background-image: url('../img/scalloped-grey-teal-flipped.svg');
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// footer {
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	width: 100%;
	// 	z-index: 10;
	// }
}

.front-page-content {
	width: 100%;
	padding: 80px 0;
	background: #fff;
	font-size: 24px;
	line-height: 1.43em;
	font-weight: 600;
	color: $dark-grey;
	@include media ($breakpoint) {
		padding: $global-padding*2 0;
		font-size: 20px;
	}
}

// -----------------------------------
// Component Styles
// -----------------------------------

.page-subsection {
	@media print {
		padding-top: 0;
		font-size: 11px !important;
		line-height: 1.4 !important;
	}
	* + p {
		margin: $global-padding/2 0 0 0;
		@media print {
			margin: 1rem 0 0;
		}
	}
	ul {
		margin: $global-padding 0 0 -1.5em;
		@include media($mobileOnly) {
			margin:  $global-padding 0 0;
		}
		padding: 0;
		li {
			margin: 0 0 $global-padding*0.8 0;
			padding-left: 1.5em;
			@include media($mobileOnly) {
				padding-left: 1em;
			}
			list-style: none;
			position: relative;
			@media print {
				margin: 0 0 0 1em;
				list-style: disc;
				& + li {
					margin: .5rem 0 0 1em;
				}
			}
			&:before {
				content: " ";
				display: block;
				width: 4px;
				height: 4px;
				position: absolute;
				top: 0.7em;
				left: 0;
				background: $dark-grey;
				.teal & {
					background: $brand-teal;
				}
				.orange & {
					background: $brand-orange;
				}
				border-radius: 50%;
				@media print {
					display: none;
				}
			}
			ul {
				margin: 0;
				li {
					margin: 0;
					&:before {
						width: 6px;
						height: 6px;
						top: 0.6em;
						background: transparent;
					}
					.orange & {
						&:before {
						background: transparent;
							border: 1px solid $brand-orange;
						}
					}
					.blue & {
						&:before {
						background: transparent;
							border: 1px solid $brand-blue;
						}
					}
					.teal & {
						&:before {
						background: transparent;
							border: 1px solid $brand-teal;
						}
					}
					.grey & {
						&:before {
						background: transparent;
							border: 1px solid $grey;
						}
					}
				}
			}
		}
	}
}

@keyframes scribble {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

.scribble {
	path {
		stroke-width: 3;
		stroke-dasharray: 1000 1000;
		animation: scribble 2s linear forwards;
	}
}

.checklist {
	margin: $global-padding 0;
	padding: $global-padding;
	background: $lightest-grey;
	border-radius: $global-padding;
	font-weight: bold;
	@media print {
		background: none;
	}
	ul {
		margin: 0;
		padding: $global-padding/2 0 0 0;
		font-weight: normal;
		li {
			list-style: none;
			margin: 0 0 0 $global-padding + 5px;
			border-bottom: 1px solid $light-grey;
			padding: $global-padding/2 0;
			transition: all 0.2s linear;
			position: relative;
			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}
			&:before, &:after, .scribble {
				display: inline-block;
				content: " ";
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background: transparent !important;
				position: absolute;
				left: -$global-padding - 5px;
				top: ($global-padding/2);
				transition: all 0.2s linear;
			}
			&:before {
				border-width: 1px;
				border-style: solid;
			}
			&:after {
				transform: scale(0);
			}
			.scribble {
				display: none;
			}
			&:hover {
				cursor: pointer;
			}
			&.active {
				opacity: 0.55;
				transition-delay: 0.4s;
			}
			main.orange & {
				.scribble {
					path {
						stroke: $brand-orange;
					}
				}
				&:before {
					border-color: $brand-orange;
				}
				&:after {
					background: $brand-orange;
				}
				&:hover {
					color: $brand-orange;
				}
			}
			main.blue & {
				.scribble {
					path {
						stroke: $brand-blue;
					}
				}
				&:before {
					border-color: $brand-blue;
				}
				&:after {
					background: $brand-blue;
				}
				&:hover {
					color: $brand-blue;
				}
			}
			main.teal & {
				.scribble {
					path {
						stroke: $brand-teal;
					}
				}
				&:before {
					border-color: $brand-teal;
				}
				&:after {
					background: $brand-teal;
				}
				&:hover {
					color: $brand-teal;
				}
			}
		}
	}
}

.converted-list {
	ul {
		margin: 0;
		padding: 0;
		li {
			margin: 0;
			display: block;
			list-style: none;
			padding: 15px 0;
			border-bottom: 1px solid $light-grey;
			&:before {
				display: none;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

.expandable-tab-style {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	transition: all 0.2s ease;
	&:before {
		transform: rotate(90deg);
	}
	&:before, &:after {
		position: absolute;
		top: 17px;
		right: 20px;
		content: " ";
		display: block;
		width: 15px;
		height: 4px;
		background: $grey;
		border-radius: 45px;
		opacity: 0;
		transition: opacity 0.2s linear;
	}
	&:hover {
		cursor: pointer;
	}
	&:not(.active):hover {
		&:before {
			opacity: 1;
		}
	}
	&:not(.active):hover, &.active {
		&:after {
			opacity: 1;
		}
	}
}

// -----------------------------------
// Page/Layout Styles
// -----------------------------------

.page-intro {
	margin-bottom: 46px;
	font-weight: 600;
	color: $dark-grey;
	font-size: 22px;
	line-height: 1.42em;
	@include media($laptopHeightOnly) {
		//font-size: 22px;
	}
	@include media($laptopHeightOnly) {
		@include font-size(20px);
	}
	@media print {
		font-size: 20px;
	}
}

.page-subsection {
	margin-bottom: 97px;
	@media print {
		margin-bottom: 0 !important;
	}
	font-size: 17px;
	line-height: 27px;
	color: $dark-grey;
	@include media($ptabAndLess) {
		margin-bottom: 50px;
	}
	h2 {
		margin-bottom: 28px;
	}
	h3 {
		margin: 60px 0 45px 0;
		font-size: 22px;
		line-height: 1.36em;
		font-weight: 600;
		color: $dark-grey;
		@include media($ptabAndLess) {
			margin: 50px 0 $global-padding 0;
			font-size: 20px;
		}
		@media print {
			font-size: 1.2rem;
			margin: 2rem 0 1rem;
		}
	}
	h4 {
		margin-top: 50px;
	}
}

.section-intro {
	margin-bottom: 40px;
}

.layout-quote {
	margin-top: 100px;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	color: #fff;
	@media print {
		color: #000;
		background: #fff !important;
		margin: 1em 2em !important;
	}
	@include media($ptabAndLess) {
		margin-top: 50px;
		flex-flow: column nowrap;
	}
	.quote {
		padding: 66px 0;
		.container {
			max-width: 755px;
			margin-right: 50px;
			@include media($ptabAndLess) {
				margin-right: 0;
			}
			@media print {
				//max-width: 100%;
				margin: auto;
				border-top: .25px solid;
				border-bottom: .25px solid;
				padding: 1em 0;
			}
		}
		@media print {
			background: #fff !important;
			padding: 0 !important;
		}
	}
	.quote, .quote-img {
		flex-grow: 1;
	}
	blockquote, .quote-author {
		width: 100%;
	}
	blockquote {
		margin: 0;
		font-weight: 600;
		font-size: 40px;
		line-height: 1.22em;
		@include media($laptopHeightOnly) {
			font-size: 29px;
		}
		@include media($smDeskAndLess) {
			font-size: 29px;
		}
		@include media($ptabAndLess) {
			font-size: 20px;
		}
		@media print {
			font-size: 14px;
		}
	}
	.quote-author {
		margin-top: 25px;
		font-size: 17px;
		line-height: 22px;
		font-weight: 600;
		text-transform: uppercase;
		@include media($ptabAndLess) {
			font-size: 14px;
		}
		@media print {
			font-size: 10px;
			line-height: 1;
		}
	}
	&.has-image {
		.quote {
			width: 60%;
			padding-left: 100px;
			position: relative;
			@include media($smDeskAndLess) {
				padding-left: 50px;
			}
			@include media($ptabAndLess) {
				padding: $global-padding 0;
				width: 100%;
				order: 2;
			}
			&:after {
				content: " ";
				display: block;
				position: absolute;
				top: 0;
				left: 100%;
				height: 100%;
				width: 25px;
				@include media($ptabAndLess) {
					display: none;
				}
			}
			@media print {
				width: auto;
				padding-left: 0;
				&:after {
					background: none !important;
				}
			}
		}
		.quote-img {
			width: 40%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			@include media($ptabAndLess) {
				width: 100%;
				min-height: 360px;
				order: 1;
				background-position: center top;
			}
			@include media($mobileOnly) {
				min-height: 260px;
			}
			@media print {
				display: none;
			}
		}
	}
	&.blue {
		background: $brand-blue;
		&.has-image {
			.quote {
				background: $brand-blue;
				&:after {
					background: url('../img/scalloped-edge-blue.svg') repeat-y left center;
				}
			}
		}
	}
	&.teal {
		background: $brand-teal;
		&.has-image {
			.quote {
				background: $brand-teal;
				&:after {
					background: url('../img/scalloped-edge-teal.svg') repeat-y left center;
				}
			}
		}
	}
	&.orange {
		background: $brand-orange;
		&.has-image {
			.quote {
				background: $brand-orange;
				&:after {
					background: url('../img/scalloped-edge-orange.svg') repeat-y left center;
				}
			}
		}
	}
	&.grey {
		background: $grey;
		&.has-image {
			.quote {
				background: $grey;
				&:after {
					background: url('../img/scalloped-edge-grey.svg') repeat-y left center;
				}
			}
		}
	}
	+ .container-narrow {
		margin-top: $global-padding*3;
		@include media ($breakpoint) {
			margin-top: $global-padding;
		}
		@media print {
			margin-top: 0;
		}
	}
}

.layout-grid-accordion {
	@include media ($mobileOnly) {
		font-size: 16px;
		line-height: 1.35em;
	}
	.accordion-items {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		@include media ($breakpoint) {
			display: block;
		}
		@media print {
			display: block;
		}
	}
	.accordion-title, .accordion-contents {
		margin-bottom: 10px;
		padding: ($global-padding - 10) $global-padding;
		border-radius: $global-padding;
		@include media ($breakpoint) {
			margin-bottom: 5px;
		}
	}
	.accordion-title {
		width: 49%;
		color: #fff;
		font-weight: bold;
		line-height: 27px;
		@extend .expandable-tab-style;
		padding-right: 42px;
		@include media ($breakpoint) {
			width: 100%;
		}
		@media print {
			width: 100%;
			background: #fff !important;
			color: #000;
			padding: 0;
		}
		&.active {
			margin-bottom: -20px;
			padding-bottom: 40px;
			border-radius: $global-padding $global-padding 0 0;
			@include media ($mobileOnly) {
				padding-bottom: $global-padding;
			}
		}
		&:not(.active):hover, &.active {
			background: $lightest-grey;
			color: $dark-grey;
			&:after {
				opacity: 1;
			}
		}
	}
	.accordion-contents {
		background: $lightest-grey;
		width: 100%;
		flex-grow: 1;
		// opacity: 0;
		// transform: scale(1, 0);
		transform-origin: center top;
		// transition: all 0.2s linear;
		// &.active {
		// 	opacity: 1;
		// 	transform: scale(1);
		// }
		&.convert-yes {
			@extend .converted-list;
		}
		ul, p, h3, h4, h5 {
			margin-bottom: $global-padding/2;
		}
		h3, h4, h5 {
			margin-top: 0;
			font-weight: bold;
		}
		h5 {
			font-size: 20px;
		}
		@media print {
			display: block !important;
			background: #fff;
			color: #000;
			padding: 0;
		}
	}
}

.layout-styled_list {
	ul {
		margin-top: $global-padding/2;
		margin-bottom: $global-padding;
		padding: $global-padding/2 $global-padding;
		border-radius: $global-padding;
		background: $lightest-grey;
	}
}

.layout-list_w_expandable {
	margin-bottom: $global-padding*2;
	.checklist {
		margin-bottom: 10px;;
		background: #fff;
		border: 1px solid;
		.list-title {
			font-size: 24px;
		}
		li {
			border: 0;
		}
		main.orange & {
			border-color: $brand-orange;
		}
		main.blue & {
			border-color: $brand-blue;
		}
		main.teal & {
			border-color: $brand-teal;
		}
	}
	.full-width-expand {
		border-radius: $global-padding;
		color: #fff;
		transition: background 0.2s ease;
		.expand-tab {
			display: block;
			padding: $global-padding/2 $global-padding;
			font-weight: bold;
			@extend .expandable-tab-style;
			&:before, &:after {
				top: $global-padding/1.5;
				right: $global-padding;
			}
			&.active {
				padding: $global-padding $global-padding 0 $global-padding;
				&:before, &:after {
					background: $grey;
				}
			}
		}
		.expand-content {
			padding: $global-padding;
			@extend .converted-list;
		}
		&.active {
			background: $lightest-grey;
			color: $dark-grey;
		}
		&:not(.active) {
			main.orange & {
				background: $brand-orange;
			}
			main.blue & {
				background: $brand-blue;
			}
			main.teal & {
				background: $brand-teal;
			}
			main.grey & {
				background: $grey;
			}
			main.orange &, main.blue &, main.teal &, main.grey & {
				&:hover {
					background: $lightest-grey;
					color: $dark-grey;
				}
			}
		}
	}
}

.layout-health_tip {
	position: relative;
	@include media ($ptabAndLess) {
		margin-top: $global-padding;
	}
	.health-tip-bubble {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 8;
		height: 256px;
		padding: ($global-padding + 10px) $global-padding;
		display: flex;
		
		@media print {
			display: none;
		}
		html.ie9 & {
			display: block;
		}
		justify-content: space-between;
		align-items: center;
		border-radius: 0 125px 125px 0;
		background: $grey;
		@at-root .health-tip-type-resource#{&} {
			background: $brand-blue;
			a {
				color: #fff;
			}
		}
		color: #fff;
		overflow: hidden;
		transition: all 0.3s linear;
		@include media ($laptopHeightOnly) {
			height: 200px;
		}
		@include media ($homeBP1) {
			height: 200px;
			padding: $global-padding ($global-padding - 10px) ;
		}
		@include media ($homeBP2) {
			height: 150px;
			padding: $global-padding $global-padding/2 ;
		}
		@include media ($smDeskAndLess) {
			position: static;
			height: auto;
		}
		@include media ($mobileOnly) {
			flex-flow: column nowrap;
			padding: $global-padding $global-padding/2;
			border-radius: 0;
		}
		.health-tip-icon {
			margin: 0;
			height: 100%;
			width: 200px;
			font-weight: 600;
			font-size: 22px;
			line-height: 27px;
			text-align: center;
			@include media ($laptopHeightOnly) {
				width: 150px;
				font-size: 18px;
			}
			@include media ($homeBP2) {
				width: auto;
				font-size: 18px;
			}
			@include media ($smDeskAndLess) {
				width: 150px;
			}
			@include media ($mobileOnly) {
				margin-bottom: $global-padding;
			}
			img {
				margin: 0 auto 30px auto;
				display: block;
				max-width: 65%;
				height: auto;
				width: auto;
				height: 120px;
				width: 130px;
				@include media ($laptopHeightOnly) {
					margin: 0 auto 10px auto;
				}
				@include media ($homeBP1) {
					margin: 0 auto 10px auto;
					height: 100px;
				}
				@include media ($homeBP2) {
					height: 70px;
				}
			}
		}
		.health-tip-title {
			margin-bottom: 20px;
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;
			@include media ($laptopHeightOnly) {
				font-size: 18px;
				line-height: 18px;
				text-transform: uppercase;
			}
			@include media ($smDeskAndLess) {
				font-size: 18px;
				line-height: 18px;
				text-transform: uppercase;
			}
			@include media ($mobileOnly) {
				font-size: 16px;
			}
		}
		.health-tip {
			display: none;
			margin: 0 45px 0 0;
			opacity: 0;
			transition: opacity 0.3s linear;
			@include media ($smDeskAndLess) {
				display: block;
				opacity: 1;
			}
			@include media ($mobileOnly) {
				margin: 0;
				font-size: 16px;
				line-height: 1.5em;
			}
		}
		.button {
			align-self: center;
			width: 41px;
			height: 41px;
			border: 3px solid #fff;
			border-radius: 50%;
			position: relative;
			transition: all 0.2s linear;
			html.ie9 & {
				position: absolute;
				right: 10px;
				top: 50%;
				margin-top: -20px;
			}
			@include media ($smDeskAndLess) {
				display: none;
			}
			&:before, &:after {
				position: absolute;
				top: 16px;
				left: 8px;
				content: " ";
				display: block;
				width: 20px;
				height: 4px;
				background: #fff;
				border-radius: 4px;
				transition: opacity 0.2s linear;
			}
			&:after {
				transform: rotate(90deg);
			}
			&:hover {
				cursor: pointer;
				background: rgba(255,255,255,0.2);
			}
		}
		&.open {
			//background: rgba($grey,1);
			@include media ($homeBP2) {
				height: auto;
			}
			.health-tip {
				opacity: 1;
			}
			.button {
				&:after {
					opacity: 0;
				}
			}
		}
	}
}

.layout-content_reveal {
	.reveal {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: $brand-blue;
		font-weight: bold;
		@media print {
			display: none;
		}
		svg {
			margin-left: 10px;
			width: 20px;
			height: 20px;
			transition: transform 0.2s ease;
		}
		&.active, &:hover {
			color: $grey;
		}
		&:hover {
			cursor: pointer;
		}
		&.active {
			svg {
				transform: rotate(225deg);
			}
		}
	}
	.to-reveal {
		margin-top: $global-padding;
		display: none;
		@media print {
			display: block !important;
			margin-top: 0;
		}
	}
}

.layout-reveal_cards {
	margin: 90px 0 95px 0;
	display: flex;
	justify-content: center;
	align-items: stretch;
	html.ie9 & {
		display: block;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	@include media($smDeskAndLess) {
		flex-flow: column nowrap;
		padding: 0 $wide-container-padding*2.5;
	}
	@include media($ptabAndLess) {
		margin: $global-padding 0;
		padding: 0 $wide-container-padding;
	}
	@include media($mobileOnly) {
		padding: 0;
		margin: 0;
	}
	.reveal-card {
		width: 340px;
		height: 320px;
		margin: 0 2px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border-radius: 45px;
		color: #fff;
		overflow: hidden;
		position: relative;
		
		html.ie9 & {
			float: left;
		}
		@include media($smDeskAndLess) {
			width: 90%;
			height: auto;
			min-height: 200px;
			margin: $global-padding/4 auto;
			padding: $global-padding;
			background-position: center top;
		}
		.card-title, .card-icon, .card-contents, .card-fx {
			position: absolute;
			transition: all 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
			@include media($smDeskAndLess) {
				position: relative;
			}
		}
		.card-title, .card-icon {
			z-index: 2;
			bottom: $global-padding;
			@include media($smDeskAndLess) {
				display: inline-block;
				bottom: auto;
			}
		}
		.card-title, {
			height: 50px;
			left: $global-padding + 65px;
			font-size: 55px;
			font-weight: 600;
			line-height: 48px;
			@include media($smDeskAndLess) {
				margin-left: $global-padding;
				left: auto;
			}
		}
		.card-icon {
			left: $global-padding;
			@include media($smDeskAndLess) {
				left: auto;
			}
			svg {
				width: 55px;
				height: 55px;
				@include media($smDeskAndLess) {
					width: 70px;
					height: 70px;
				}
			}
		}
		.card-fx, .card-contents {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.card-contents {
			z-index: 1;
			padding: $global-padding;
			font-size: 17px;
			line-height: 27px;
			opacity: 0;
			border-radius: 45px;
			@include media($smDeskAndLess) {
				opacity: 1;
				background: transparent;
				padding: $global-padding;
				position: relative;
			}
			@include media($ptabAndLess) {
				padding: $global-padding 0;
			}
		}
		.card-fx {
			z-index: 0;
		}
		// &.bg-blue {
		// 	.card-fx {
		// 		z-index: 0;
		// 		@include background-image(linear-gradient(top, rgba($brand-blue,0) 0%,rgba($brand-blue,1) 100%));
		// 	}
		// }
		// &.bg-teal {
		// 	.card-fx {
		// 		z-index: 0;
		// 		@include background-image(linear-gradient(top, rgba($brand-teal,0) 0%,rgba($brand-teal,1) 100%));
		// 	}
		// }
		// &.bg-orange {
		// 	.card-fx {
		// 		z-index: 0;
		// 		@include background-image(linear-gradient(top, rgba($brand-orange,0) 0%,rgba($brand-orange,1) 100%));
		// 	}
		// }
		// &.bg-grey {
		// 	.card-fx {
		// 		z-index: 0;
		// 		@include background-image(linear-gradient(top, rgba($grey,0) 0%,rgba($grey,1) 100%));
		// 	}
		// }
		@include media($desk) {
			&:hover {
				.card-title, .card-icon {
					bottom: calc(100% - (50px + #{$global-padding}));
				}
				.card-contents {
					padding: ($global-padding + 50px + 18px) $global-padding $global-padding;
					opacity: 1;
					transition-delay: 0.15s;
				}
			}
		}
		@include media($smDeskAndLess) {
			.card-fx {
				position: absolute;
			}
			&.bg-blue {
				.card-fx {
					@include background-image(linear-gradient(top, rgba($brand-blue,0) 0%,rgba($brand-blue,1) 100%));
				}
			}
			&.bg-teal {
				.card-fx {
					@include background-image(linear-gradient(top, rgba($brand-teal,0) 0%,rgba($brand-teal,1) 100%));
				}
			}
			&.bg-orange {
				.card-fx {
					@include background-image(linear-gradient(top, rgba($brand-orange,0) 0%,rgba($brand-orange,1) 100%));
				}
			}
			&.bg-grey {
				.card-fx {
					@include background-image(linear-gradient(top, rgba($grey,0) 0%,rgba($grey,1) 100%));
				}
			}
		}
	}
}

.levels-of-evidence {
	margin: $global-padding*2 auto;
	padding: 0 $global-padding*3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	max-width: $max-width - ($wide-container-padding*3);
	@include media($smDeskAndLess) {
		padding: 0 $global-padding;
		max-width: none;
	}
	@include media($ptabAndLess) {
		margin: $global-padding 0;
	}
	h3 {
		width: 100%;
		text-align: center;
	}
	&.orange {
		.level {
			// background: rgba($brand-orange, 0.2);
			border-color: $brand-orange;
		}
	}
	&.blue {
		.level {
			// background: rgba($brand-blue, 0.2);
			border-color: $brand-blue;
		}
	}
	&.teal {
		.level {
			// background: rgba($brand-teal, 0.2);
			border-color: $brand-teal;
		}
	}
	.level {
		width: 300px;
		height: 300px;
		border-radius: 50%;
		padding: $global-padding*2 $global-padding;
		text-align: center;
		border-width: 3px;
		border-style: solid;
		@include media($homeBP1) {
			width: 280px;
			height: 280px;
		}
		@include media($homeBP2) {
			width: 250px;
			height: 250px;
			padding: $global-padding;
		}
		@include media($smDeskAndLess) {
			width: 100%;
			height: auto;
			margin-bottom: $global-padding/2;
			border-radius: $global-padding;
			// border-width: 0;
			text-align: left;
		}
		.level-level {
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 24px;
			color: $grey;
			@include media($smDeskAndLess) {
				color: $brand-teal;
			}
		}
		.level-desc {
			font-size: 17px;
			@include media($homeBP1) {
				line-height: 24px;
			}
			@include media($homeBP2) {
				line-height: 22px;
			}
		}
	}
}

.resources-downloads {	
	@media print {
		margin: 2rem 0;
	}
	.h2 {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: $grey;
		@media print {
			color: #000 !important;
		}
		svg {
			margin-right: 17px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			transition: all 0.2s ease;
			@media print {
				display: none;
			}
		}
		&:hover {
			cursor: pointer;
		}
		&.blue svg {
			color: $brand-blue;
		}
		&.teal svg {
			color: $brand-teal;
		}
		&.orange svg {
			color: $brand-orange;
		}
		&.grey svg {
			color: $grey;
		}
	}
	.resources-list {
		margin-top: $global-padding;
	}
}

p + blockquote:not(.statistic) {
	color: $grey;
	border-top: 1px solid $brand-teal;
	border-bottom: 1px solid $brand-teal;
	.orange & {
		border-top: 1px solid $brand-orange;
		border-bottom: 1px solid $brand-orange;
	}
	margin: 1.5rem 0;
	padding: 1em 0;
}

blockquote.statistic {
	position: relative;
	padding: 1em;
	margin: 1.5em -1em;
	color: $grey;
	@media print {
		color: #000;
	}
	&:before {
		content:'';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid $brand-teal;
		border-radius: 1.25em;
		@media print {
			display: none;
		}
	}
	b,
	strong {
		display: block;
		line-height: 1;
		font-size: 4rem;
		color: $brand-teal;
		@media print {
			color: #000;
			font-size: 2rem;
		}
		font-weight: 100;
		margin: .05em 0;
	}
	@include media($ptab) {
		float: left;
		width: 48%;
		padding: 2em;
		margin: 3em 0;
		clear: left;
		&:before {
			border-radius: 2em;
		}
		&:nth-child(even) {
			float: right;
			clear: right;
			&:after {
				content:'';
				display: block;
				clear: both;
			}
		}
		p {
			//font-size: 1.4rem;
		}
	}
	& + p {
		clear: both;
	}
}

@media print {
	p a {
		color: #000;
		text-decoration: underline;
	} 
	
	p a:after {
		content: ' (' attr(href) ')';
		text-decoration: none;
	}
	.screen-only {
		display: none;
	}
}

button {
	background: $lightest-grey;
	color: $dark-grey;
	border-radius: 30px;
	border: none;
	padding: 10px 21px;
}

h2.print,
span.print {
	color: $grey;
	font-weight: bold;
	cursor: pointer;
	//font-weight: 600;
	//font-size: 22px;
	//text-align: center;
	&:hover {
		color: $brand-teal;
		.orange & {
			color: $brand-orange;
		}
	}
	svg {
		height: 30px;
		width: 30px;
		margin: -2px 17px 0 10px;
		color: $grey;
		vertical-align: middle;
		.orange & {
			color: $brand-orange;
		}
		.teal & {
			color: $brand-teal;
		}
	}
}