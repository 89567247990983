$brand-blue: rgba(0,82,154,1);
$brand-teal: rgba(126,198,188,1);
$brand-orange: rgba(239,91,58,1);

$color-caregivers: $brand-orange;
$color-physicians: $brand-teal;

$grey: rgba(130,145,149,1);
$dark-grey: #4A4A4A;
$light-grey: #D8D8D8;
$lightest-grey: #F5F5F5;