@font-face {
	font-family: 'Cabin';
	src: url('../fonts/Cabin-Regular.otf');
}
@font-face {
	font-family: 'Cabin';
	src: url('../fonts/Cabin-SemiBold.otf');
	font-weight: 600;
}
@font-face {
	font-family: 'Cabin';
	src: url('../fonts/Cabin-Bold.otf');
	font-weight: bold;
}

$font: 'Cabin', sans-serif;

h1, h2, h3, h4, h5 {

}

h1 {
  @include font-size(24px);
  margin-bottom:15px;
}

h2 {
  @include font-size(22px);
}

h3 {
  @include font-size(22px);
}
